import * as React from "react"
import Layout from '../../components/layout'
import { Link } from "gatsby"

export default function Favorites() {
  return(
    <Layout>

      <Link to="/" state={{ item: 'writing' }} className="flex space-x-2 items-center hover:underline mb-7">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
        </svg>
        <div >
          back to all writing
        </div>
      </Link>

      <div className="text-2xl font-bold mb-2">
        Current food for thought
      </div>

      <div className="text-gray-600 mb-6">
        1/25/22
      </div>

      <div className="space-y-4">
        <div>
          Some ideas, concepts, and questions that I've been thinking about (observing in daily life, learning more about, contemplating possible answers to) recently:
        </div>
        <div className="space-y-1">
          <li>
            Universal truths versus local data
          </li>
          <li>
            Perfectionism-induced procrastination
          </li>
          <li>
            Procedural versus distributive justice
          </li>
          <li>
            Applying greedy algorithms to questions of personal purpose and fulfillment
          </li>
          <li>
            College functionally becomes just about running towards the next best thing
          </li>
          <li>
            The benefits of intentional/meaningful inefficiency
          </li>
          <li>
            Every implementation of scientific and technological innovations in society is political
          </li>
          <li>
            Can social entrepreneurship exist without undermining the necessity of public solutions for public problems?
          </li>
          <li>
            Decentering technology in societal goals and design
          </li>
        </div>
      </div>

      <div className="h-60">
      </div>
      
    </Layout>
  )
}